function renderDefaultDropOffLocationInfo({name, comment, street, zip, city, tel, email, businessHours, imageUrl}) {
    return `<div class="info-window">
<p class="name">${name}</p>
<p class="address">${street},<br />${zip} ${city}</p>
${!tel && !email ? '' : `<p class="contact">${[tel && `<a href="tel:${tel}">${tel}</a>`, email && `<a href="mailto:${email}">${email}</a>`].filter(_ => _).join(' | ')}</p>`}
${!businessHours ? '' : `<p class="business-hours">${businessHours}</p>`}
${!comment ? '' : `<p class="comment">${comment}</p>`}
${!imageUrl ? '' : `<p class="image"><img src="${imageUrl}" /></p>`}
</div>`
}

class DropOffLocationOpeningHours {

    constructor({containerId, apiKey: key, language = 'de'}, map) {
        const placesService = new google.maps.places.PlacesService(map)
        const cache = {}

        this.render = ({googlePlaceId: placeId, businessHours = ''}) => {
            (!placeId && Promise.resolve() || (cache[placeId] || (cache[placeId] = new Promise((resolve, reject) => placesService.getDetails({
                placeId,
                fields: ['opening_hours'],
            }, (place, status) => status !== google.maps.places.PlacesServiceStatus.OK ? reject() : resolve(place))))))
                .then(({opening_hours: {weekday_text: weekdays = businessHours.split(',').map(_ => _.trim())} = {}} = {}) => setTimeout(() => {
                    const element = document.getElementById(containerId).querySelector('.info-window .business-hours')
                    element.innerHTML = `<table class="business-hours">${weekdays.map(weekday => weekday.split(':')).map(([weekday, ...hours]) => `<tr><th>${weekday}</th><td>${hours.join(':')}</td></tr>`)}</table>`
                    element.childNodes.forEach(node => node.nodeType === 3 && element.removeChild(node)) // HACK, otherwise there's a ",,,,,," text node before the table ... noooo idea why
                }, 100), () => cache[placeId] = null)

            return '<span class="lds-ring"><span></span><span></span><span></span><span></span></span>'
        }
    }
}

class DropOffLocationsSearch {

    constructor(map) {
        // Create the search box and link it to the UI element.
        const input = document.getElementById('pac-input')
        const searchBox = new google.maps.places.SearchBox(input)
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)

        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', () => searchBox.setBounds(map.getBounds()))

        let markers = []
        // Listen for the event fired when the user selects a prediction and retrieve more details for that place.
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces()

            if (!places.length) {
                return
            }
            // Clear out the old markers.
            markers.forEach((marker) => marker.setMap(null))
            markers = []

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds()
            places.forEach((place) => {
                if (!place.geometry) {
                    console.warn('Returned place contains no geometry')
                    return
                }
                // Create a marker for each place.
                markers.push(new google.maps.Marker({
                    map,
                    icon: {
                        url: place.icon,
                        size: new google.maps.Size(71, 71),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(17, 34),
                        scaledSize: new google.maps.Size(25, 25),
                    },
                    title: place.name,
                    position: place.geometry.location,
                }))

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport)
                } else {
                    bounds.extend(place.geometry.location)
                }
            })
            map.fitBounds(bounds)
        })
    }
}

class DropOffLocationsMap {

    constructor({containerId, center, apiKey, language}, locations, infoRenderer = renderDefaultDropOffLocationInfo) {
        const map = new google.maps.Map(document.getElementById(containerId), {
            zoom: !center ? 5 : 6,
            center: center && {lat: center.latitude, lng: center.longitude} || {lat: 49.92612696738593, lng: 11.258057500000014},
        })
        const openingHours = new DropOffLocationOpeningHours({containerId, apiKey, language}, map)
        const infoWindow = new google.maps.InfoWindow()
        const createMarker = ({name, longitude: lng, latitude: lat, ...location}) => {
            const marker = new google.maps.Marker({position: {lng, lat}, title: name})
            marker.addListener('click', () => {
                infoWindow.close()
                infoWindow.setContent(infoRenderer({name, ...location}, openingHours))
                infoWindow.open(map, marker)
            })
            return marker
        }

        new MarkerClusterer(map, locations.map(createMarker), {
            styles: [
                {
                    width: 30,
                    height: 30,
                    className: 'custom-clustericon-1',
                },
                {
                    width: 40,
                    height: 40,
                    className: 'custom-clustericon-2',
                },
                {
                    width: 50,
                    height: 50,
                    className: 'custom-clustericon-3',
                },
            ],
            clusterClass: 'custom-clustericon',
        })

        new DropOffLocationsSearch(map)
    }
}

class DropOffLocationRegions {

    constructor() {
        const toggleLocationRows = ({countryCode, region}) => document.querySelectorAll('.locations-table tbody tr').forEach(row => row.classList.toggle('disabled', countryCode !== 'all' && (!!countryCode && row.dataset.countryCode !== countryCode || region !== 'all' && !!region && row.dataset.region !== region)))
        const removeDefaultOption = select => select.querySelectorAll('option[data-default]').forEach(option => option.parentNode.removeChild(option))

        const container = document.querySelector('#drop-off-location-regions')
        const controlButtons = container.querySelectorAll('.controls button')
        const countryContainer = container.querySelector('.locations-country')
        const countrySelect = countryContainer.querySelector('select')
        const regionContainer = container.querySelector('.locations-region')
        const regionSelect = regionContainer.querySelector('select')
        const locationsTable = container.querySelector('.locations-table')

        const regionsByCountry = Array.from(regionSelect.querySelectorAll('option[data-country-code]')).reduce((byCountry, option) => !option.value ? byCountry : ({
            ...byCountry,
            [option.dataset.countryCode]: [...(byCountry[option.dataset.countryCode] || []), option.parentNode.removeChild(option)],
        }), {})

        countrySelect.addEventListener('change', ({target: {value: countryCode}}) => {
            removeDefaultOption(countrySelect)
            regionContainer.classList.toggle('disabled', !countryCode || countryCode === 'all')
            locationsTable.classList.toggle('disabled', !countryCode)

            regionSelect.querySelectorAll('option[data-country-code]').forEach(option => option.parentNode.removeChild(option))
            if (countryCode && countryCode !== 'all') {
                regionsByCountry[countryCode].forEach(option => regionSelect.appendChild(option.cloneNode(true)))
            }

            toggleLocationRows({countryCode})
        })
        regionSelect.addEventListener('change', ({target: {value: region}}) => {
            removeDefaultOption(regionSelect)
            toggleLocationRows({region, countryCode: countrySelect.value})
        })

        controlButtons.forEach(button => button.addEventListener('click', ({target: {value: toggle}}) => container.classList.toggle('disabled', toggle !== 'show')))
    }
}